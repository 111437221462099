import React, { useEffect, useContext } from 'react'

import SEO from '../SEO'
import GlobalContext from '../../context/GlobalContext'

const headerConfigDefault = {
  theme: 'light',
  variant: 'primary',
  align: 'right',
  isFluid: false,
  button: 'null', // trial, cart, cta, account, null
  buttonText: 'Contact Us',
}

const footerConfigDefault = {
  theme: 'dark',
  style: 'style2',
}

const PageWrapper = ({
  children,
  headerConfig = null,
  footerConfig = null,
  seoConfig,
}) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.themeDark) {
      gContext.setHeader({
        ...headerConfigDefault,
        ...headerConfig,
        theme: 'dark',
      })
      gContext.setFooter({
        ...footerConfigDefault,
        ...footerConfig,
        theme: 'dark',
      })
    } else {
      gContext.setHeader({ ...headerConfigDefault, ...headerConfig })
      gContext.setFooter({ ...footerConfigDefault, ...footerConfig })
    }
  }, [gContext.themeDark, headerConfig, footerConfig])

  return (
    <>
      <SEO {...seoConfig} />
      {children}
    </>
  )
}

export default PageWrapper
