import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import imgFavicon from '../../assets/favicon.png'

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            script={[
              {
                type: 'application/ld+json',
                innerHTML: JSON.stringify({
                  '@context': 'https://www.schema.org',
                  '@graph': [
                    {
                      '@type': 'ProfessionalService',
                      name: 'Dot Dot Data',
                      url: 'https://www.dotdotdata.com',
                      logo: 'https://www.dotdotdata.com/logo.png',
                      image: 'https://www.dotdotdata.com/logo.png',
                      description:
                        'Dot Dot Data is a software development company. We can build anything from web applications to mobile apps. We specialize in proximity based technology such as geofencing and tracking software.',
                      priceRange: '$100+',
                      telephone: '+1 (956) 624-0448',
                      address: {
                        '@type': 'PostalAddress',
                        streetAddress: '601 N Main St',
                        addressLocality: 'McAllen',
                        addressRegion: 'TX',
                        postalCode: '78504',
                        addressCountry: 'United States',
                      },
                      openingHours:
                        'Mo 01:00-01:00 Tu 01:00-01:00 We 01:00-01:00 Th 01:00-01:00 Fr 01:00-01:00',
                    },
                    {
                      '@type': 'Organization',
                      name: 'Dot Dot Data',
                      legalName: 'Dot Dot Data LLC',
                      url: 'https://www.dotdotdata.com',
                      logo: 'https://www.dotdotdata.com/logo.png',
                      image: 'https://www.dotdotdata.com/logo.png',
                      telephone: '+1 (956) 624-0448',
                      address: {
                        '@type': 'PostalAddress',
                        streetAddress: '601 N Main St',
                        addressLocality: 'McAllen',
                        addressRegion: 'TX',
                        postalCode: '78504',
                        addressCountry: 'USA',
                      },
                      foundingDate: '2018',
                      founders: [
                        {
                          '@type': 'Person',
                          name: 'Pablo Rocha',
                        },
                        {
                          '@type': 'Person',
                          name: 'Arturo Jaime Garcia',
                        },
                        {
                          '@type': 'Person',
                          name: 'Ramon Almaguer',
                        },
                      ],
                      contactPoint: [
                        {
                          '@type': 'ContactPoint',
                          contactType: 'customer service',
                          telephone: '+1 (956) 624-0448',
                          email: 'hello@dotdotdata.com',
                        },
                        {
                          '@type': 'ContactPoint',
                          contactType: 'sales',
                          telephone: '+1 (956) 624-0448',
                          email: 'hello@dotdotdata.com',
                        },
                      ],
                      sameAs: [
                        'https://www.facebook.com/dotdotdata',
                        'https://www.instagram.com/dotdotdata',
                        'https://twitter.com/dotdotdata',
                      ],
                    },
                  ],
                }),
              },
            ]}
            title={title || 'Dot Dot Data | Modern Software Solutions'}
            titleTemplate={title ? `%s | ${data.site.siteMetadata.title}` : ''}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'og:image',
                content: 'https://www.dotdotdata.com/open-graph.jpg',
              },
              {
                name: 'twitter:card',
                content: 'summary',
              },
              {
                name: 'twitter:creator',
                content: data.site.siteMetadata.author,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                property: 'twitter:image',
                content: 'https://www.dotdotdata.com/open-graph.jpg',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', '),
                    }
                  : []
              )
              .concat(meta)}
          >
            <link rel="icon" type="image/png" href={imgFavicon} />
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
